










































































import Vue from "vue";
import { Component, Prop, VModel } from "vue-property-decorator";
import TextField from "@/components/defaultLayout/util/TextField.vue";
import { ParameterInstitutionInterface } from "@/components/defaultLayout/institutions/RegisterInstitution.vue";

@Component({
  components: { TextField },
})
export default class ConfirmDialog extends Vue {
  @Prop() parameterInstitution!: ParameterInstitutionInterface;
  @VModel() confirmDialog!: boolean;

  dialogCancel(): void {
    this.confirmDialog = false;
  }

  async onRegisterInstitution(): Promise<void> {
    try {
      const data = await this.$_apiHelper().v1AdminCreateCompany({
        ...this.parameterInstitution,
        gameGroupUUID: this.parameterInstitution.gameGroup.UUID,
      });

      if (typeof data === "string") {
        this.$_haii.analytics.errorEvent(data);
        return this.$_errorMessage(data);
      }

      this.$_haii.analytics.createEvent(
        this.$_haiiName() + "_onRegisterInstitution",
        {
          ...this.parameterInstitution,
          gameGroupUUID: this.parameterInstitution.gameGroup.UUID,
        }
      );
      this.$_toastMessage("success", this.$_haiiText("registerSuccess"));
      setTimeout(() => {
        this.$router.go(0);
      }, 500);
    } catch (e) {
      this.$_errorMessage(e);
    }
  }
}
