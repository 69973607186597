
































































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import VForm from "vuetify/lib/components/VForm";
import TextField from "@/components/defaultLayout/util/TextField.vue";
import {
  CompanyListResponse,
  CompanyRequest,
  GameGroupListResponse,
  GameGroupResponse,
} from "@/generated/adminapi";
import ConfirmDialog from "@/components/defaultLayout/institutions/ConfirmDialog.vue";

export interface ParameterInstitutionInterface
  extends Omit<CompanyRequest, "gameGroupUUID"> {
  gameGroup: GameGroupResponse;
}

@Component({
  components: { ConfirmDialog, TextField },
})
export default class RegisterInstitution extends Vue {
  dialog = false;
  confirmDialog = false;
  valid = false;
  showPassword = false;
  emailOverlapFlag = false;
  codeOverlapFlag = false;

  select = null;
  institutionList = [];
  gameGroupListResponse = {} as GameGroupListResponse;

  parameterInstitution: ParameterInstitutionInterface = {
    model: "",
    companyName: "",
    adminName: "",
    email: "",
    pw: "",
    phone: "",
    comment: "",
    code: "",
    gameGroup: {
      UUID: "string",
      displayName: "string",
      enabledFlag: true,
      demoFlag: false,
    },
    enabledFlag: true,
  };

  demoGameGroupList = [];
  gameGroupList = [];

  rules = {
    institutionRules: [
      (v: string): boolean | string => !!v || "Institution is required",
    ],

    nameRules: [(v: string): boolean | string => !!v || "Name is required"],

    emailRules: [
      (v: string): boolean | string => !!v || "E-mail is required",
      (v: string): boolean | string => /.+@.+/.test(v) || "E-mail is invalid.",
    ],
    codeRules: [
      (v: string): boolean | string => !!v || "code is required",
      (v: string): boolean | string =>
        v?.length <= 8 || "Password must be less than 8 characters",
      (v: string): boolean | string =>
        v?.length >= 4 || "Password must be more than 4 characters",
    ],
    passwordRules: [
      (v: string): boolean | string => !!v || "Password is required",
      (v: string): boolean | string =>
        v?.length >= 10 || "Password must be more than 10 characters",
    ],

    phoneRules: [
      (v: string): boolean | string => {
        const regExp =
          /01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$|01([0|1|6|7|8|9])([0-9]{7,8})/;
        if (v) {
          return regExp.test(v) || this.$_haiiText("phoneFormatError");
        }
        return true;
      },
    ],

    modelRules: [(v: string): boolean | string => !!v || "This is required"],
  };

  get confirmBtnFlag(): boolean {
    return !(this.codeOverlapFlag && this.emailOverlapFlag);
  }

  get phoneMaxLength(): number {
    const regExp = /([0-9]{10,11})/g;
    if (regExp.test(this.parameterInstitution.phone)) {
      return 11;
    }
    return 13;
  }

  get modelList(): Array<string> {
    if (this.parameterInstitution.companyName == "(주)Haii") {
      this.parameterInstitution.model = "본사";
      return ["본사"];
    } else if (this.parameterInstitution.companyName == "DEMO") {
      this.parameterInstitution.model = "DEMO";
      return ["DEMO"];
    }
    return ["B2C", "B2B", "B2G"];
  }

  get gameGroup(): string[] {
    if (this.parameterInstitution.companyName == "DEMO") {
      return this.demoGameGroupList;
    } else {
      return this.gameGroupList;
    }
  }

  async created(): Promise<void> {
    try {
      await this.fetchData();
    } catch (error) {
      this.$_errorMessage(error);
    }
  }

  async fetchData(): Promise<void> {
    try {
      const companyList = await this.$_apiHelper().v1AdminListCompaniesName();
      const gameGroupListData = await this.$_apiHelper().v1AdminListGameGroup();

      this.handleFetchData(companyList, gameGroupListData);
    } catch (error) {
      this.$_errorMessage(error);
    } finally {
      this.$_changeLoadingState(false);
    }
  }

  handleFetchData(
    companyList: CompanyListResponse | string,
    gameGroupList: GameGroupListResponse | string
  ): void {
    if (typeof companyList === "string") {
      this.$_errorMessage(companyList);
      return;
    }
    this.institutionList = companyList.companies.map((company) => {
      return company.companyName;
    });

    if (typeof gameGroupList === "string") {
      this.$_errorMessage(gameGroupList);
      return;
    }

    if (gameGroupList.total === 0) {
      this.$_errorMessage(this.$_haiiText("noData"));
      return;
    }

    this.parameterInstitution.gameGroup = gameGroupList.gameGroups[0];

    gameGroupList.gameGroups.forEach((gameGroupResponse) => {
      if (gameGroupResponse.demoFlag) {
        this.demoGameGroupList.push(gameGroupList.gameGroups[0]);
        this.demoGameGroupList.push(gameGroupResponse);
      } else {
        this.gameGroupList.push(gameGroupResponse);
      }
    });
  }

  validate(): boolean {
    const form = this.$refs.form as typeof VForm;
    return form.validate();
  }

  async onConfirmData(): Promise<void> {
    if (!this.validate()) return;
    this.confirmDialog = true;
  }

  onGenerateRandomPassword(): void {
    this.parameterInstitution.pw = Math.random().toString(36).slice(2);
  }

  dialogCancel(): void {
    this.parameterInstitution = {
      model: "",
      companyName: "",
      adminName: "",
      email: "",
      pw: "",
      phone: "",
      comment: "",
      code: "",
      gameGroup: {} as GameGroupResponse,
      enabledFlag: true,
    };
    this.valid = false;
    this.dialog = false;
  }

  async onCheckOverlap(flag: boolean, value: string): Promise<void> {
    const data = await this.$_apiHelper().v1AdminRetrieveValidation({
      email: flag ? value : "",
      code: !flag ? value : "",
    });

    if (typeof data == "string") {
      this.$_haii.analytics.errorEvent(data);
      this.$_errorMessage(data);
      return;
    }

    if (data.code == 409) {
      if (flag) {
        this.$_errorMessage("중복된 이메일입니다. 다른 이메일로 변경해주세요");
        this.emailOverlapFlag = false;
        return;
      } else {
        this.$_errorMessage("중복된 코드입니다. 다른 코드로 변경해주세요");
        this.codeOverlapFlag = false;
        return;
      }
    }
    if (flag) this.emailOverlapFlag = true;
    else {
      this.codeOverlapFlag = true;
    }
  }

  onResetValue(flag: boolean): void {
    if (flag) this.emailOverlapFlag = false;
    else this.codeOverlapFlag = false;
  }
}
