
































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import TextField from "@/components/defaultLayout/util/TextField.vue";
import DatePicker from "@/components/defaultLayout/util/DatePicker.vue";
import {
  CouponRequest,
  CouponResponse,
  GenericResponse,
} from "@/generated/adminapi";
import VForm from "vuetify/lib/components/VForm";

@Component({
  components: {
    TextField,
    DatePicker,
  },
  metaInfo(this) {
    return {
      title: this.title,
    };
  },
})
export default class Institution extends Vue {
  @Prop() readonly UUID!: string;

  title = this.$_haiiName();
  institutionInfo = {} as CouponResponse;
  originModifyData = {} as CouponRequest;
  editState = false;
  showPassword = false;
  valid = false;

  modifyData = {} as CouponRequest;

  rules = {
    commonRules: [(v) => !!v || "This is required"],

    nameRules: [(v) => !!v || "Name is required"],

    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    passwordRules: [
      (v) => {
        return v.length >= 10 || "Password must be more than 10 characters";
      },
    ],

    phoneRules: [
      (v) => {
        const regExp =
          /01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$|01([0|1|6|7|8|9])([0-9]{7,8})/;
        if (v) {
          return regExp.test(v) || this.$_haiiText("phoneFormatError");
        }
        return true;
      },
    ],

    modelRules: [(v) => !!v || "This is required"],
  };

  created(): void {
    this.fetchData();
  }

  async fetchData(): Promise<void> {
    try {
      this.$_changeLoadingState(true);

      const data = await this.$_apiHelper().v1AdminRetrieveCompany(this.UUID);

      this.handleFetchData(data);
    } catch (e) {
      this.$_errorMessage(e);
    } finally {
      this.$_changeLoadingState(false);
    }
  }

  handleFetchData(data: CouponResponse | string): void {
    if (typeof data == "string") {
      this.$_errorMessage(this.$_haiiText("noData"));
      setTimeout((): void => {
        this.$router.go(-1);
      }, 2000);
      return;
    }

    this.institutionInfo = data;

    this.originModifyData = {
      code: data.code,
      enabledFlag: data.enabledFlag,
      adminName: data.admin.adminName,
      pw: "",
      comment: data.admin.comment,
      phone: data.admin.phone,
      fbUID: data.admin.fbUID,
    };

    this.modifyData = {
      code: data.code,
      enabledFlag: data.enabledFlag,
      adminName: data.admin.adminName,
      pw: "",
      comment: data.admin.comment,
      phone: data.admin.phone,
      fbUID: data.admin.fbUID,
    };
  }

  async onModifyUser(): Promise<void> {
    try {
      if (!this.valid) return;

      const data = await this.$_apiHelper().v1AdminModifyCompany(
        this.UUID,
        this.modifyData
      );

      this.handleModifyUserData(data);

      this.editState = false;
    } catch (e) {
      this.$_errorMessage(e);
    }
  }

  validate(): boolean {
    const form = this.$refs.form as typeof VForm;
    return form.validate();
  }

  handleModifyUserData(data: CouponResponse | GenericResponse | string): void {
    if (typeof data === "string") {
      this.$_haii.analytics.errorEvent(data);
      return this.$_errorMessage(data);
    }

    this.$_haii.analytics.updateEvent(
      this.$_haiiName() + "handleModifyUserData",
      this.originModifyData,
      this.modifyData
    );
    this.editState = false;
    this.$_toastMessage("success", this.$_haiiText("editSuccess"));
    return;
  }

  onEditCancel() {
    this.modifyData = {
      code: this.institutionInfo.code,
      enabledFlag: this.institutionInfo.enabledFlag,
      adminName: this.institutionInfo.admin.adminName,
      pw: "",
      comment: this.institutionInfo.admin.comment,
      phone: this.institutionInfo.admin.phone,
      fbUID: this.institutionInfo.admin.fbUID,
    };

    this.editState = !this.editState;
  }

  onEnterKeydown(ev: DocumentEventMap["keydown"]): void {
    if (ev.code !== "Enter") {
      return;
    }
    this.onModifyUser();
  }

  beforeDestroy(): void {
    document.removeEventListener("keydown", this.onEnterKeydown);
  }
}
