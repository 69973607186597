


















































































import { Component, Vue } from "vue-property-decorator";
import Refresh from "@/components/defaultLayout/util/Refresh.vue";
import { Obj } from "@/types/objectType";
import TextField from "@/components/defaultLayout/util/TextField.vue";
import { CouponListResponse, CouponResponse } from "@/generated/adminapi";
import { DataOptions } from "vuetify";
import Pagination from "@/components/defaultLayout/util/Pagination.vue";
import RegisterInstitution from "@/components/defaultLayout/institutions/RegisterInstitution.vue";

interface InstitutionListTableDataType {
  index: number;
  institutionName: string;
  couponCode: string;
  responsibility: string;
  codeActivity: boolean;
  phone: string;
  gameGroup: string;
  model: string;
}

export type InstitutionListTableFilterType = {
  institutionName?: string;
  couponCode?: string;
};

export type institutionListQueryType = {
  filter: InstitutionListTableFilterType;
  dataOptions: DataOptions;
};

@Component({
  components: {
    RegisterInstitution,
    Refresh,
    TextField,
    Pagination,
  },
  metaInfo(this) {
    return {
      title: this.title,
    };
  },
})
export default class InstitutionList extends Vue {
  private totalNum = 0;
  title = this.$_haiiName();

  headers = [
    { text: "#", align: "center", value: "index", sortable: false },
    {
      text: this.$_haiiText("institutionName"),
      align: "center",
      value: "institutionName",
      sortable: false,
    },
    {
      text: this.$_haiiText("model"),
      value: "model",
      sortable: false,
      align: "center",
    },
    {
      text: this.$_haiiText("couponCode"),
      value: "couponCode",
      sortable: false,
      align: "center",
    },
    {
      text: this.$_haiiText("responsibility"),
      value: "responsibility",
      sortable: false,
      align: "center",
    },

    {
      text: this.$_haiiText("phone"),
      value: "phone",
      sortable: false,
      align: "center",
    },
    {
      text: this.$_haiiText("gameGroup"),
      value: "gameGroup",
      sortable: false,
      align: "center",
    },

    {
      text: this.$_haiiText("codeActivity"),
      value: "codeActivity",
      sortable: false,
      align: "center",
    },
  ];

  private institutionList = {} as CouponListResponse;

  dataOptions = {} as DataOptions;
  filter = {} as InstitutionListTableFilterType;
  companyList = [];

  readonly DEFAULT_FILTER: Required<InstitutionListTableFilterType> = {
    couponCode: "",
    institutionName: "",
  };

  readonly DEFAULT_DATA_OPTIONS: Required<DataOptions> = {
    page: 1,
    itemsPerPage: 10,
    sortBy: ["date"],
    sortDesc: [true],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
  };

  get queryDecoded(): institutionListQueryType | null {
    const query = this.$_haii.util.queryDecode(
      this.$route.query[this.$_haiiName()],
      this.DEFAULT_FILTER,
      this.DEFAULT_DATA_OPTIONS
    );

    if (query instanceof Error) {
      console.warn(this.$_haiiName(), query);
      return null;
    }

    return query as Obj<institutionListQueryType>;
  }

  get tableData(): Array<InstitutionListTableDataType> {
    if (this.institutionList.total === undefined) {
      let temp = [];
      for (let i = 0; i < 10; i++) {
        temp.push({
          index: "",
          institutionName: "",
          couponCode: "",
          responsibility: "",
          contractDuration: "",
          phone: "",
        });
      }
      this.totalNum = 10;
      return temp;
    }

    this.totalNum = this.institutionList.total;
    // histories가 없으면 에러 처리
    if (!this.institutionList.total) {
      this.$_errorMessage(this.$_haiiText("noData"));
      return [];
    }

    return this.institutionList.coupons?.map((coupon, index) => {
      return {
        ...coupon,
        index: index,
        institutionName: coupon.admin.company.companyName,
        couponCode: coupon.code,
        responsibility: coupon.admin.adminName,
        phone: coupon.admin.phone.replace(
          /^(\d{2,3})(\d{3,4})(\d{4})$/,
          `$1-$2-$3`
        ),
        codeActivity: coupon.enabledFlag,
        gameGroup: coupon.gameGroup.displayName,
        model: coupon.admin.company.model,
      };
    });
  }

  created(): void {
    this.$watch(`$route.query.${this.$_haiiName()}`, this.onRouteChanged, {
      deep: true,
      immediate: true,
    });
    this.getCompanyList();
    document.addEventListener("keydown", this.onEnterKeydown);
  }

  beforeDestroy(): void {
    document.removeEventListener("keydown", this.onEnterKeydown);
  }

  onEnterKeydown(ev: DocumentEventMap["keydown"]): void {
    if (ev.code !== "Enter") {
      return;
    }
    this.onSearch();
  }

  // 나중에 서버에서 데이터 받아오는 기능
  async fetchData(): Promise<void> {
    try {
      this.$_changeLoadingState(true);

      const data = await this.$_apiHelper().v1AdminListCompanies({
        filter: this.filter,
        dataOptions: this.dataOptions,
      });

      this.handleFetchData(data);
    } catch (error) {
      this.$_errorMessage(this.$_haiiText("noData"));
    } finally {
      this.$_changeLoadingState(false);
    }
  }

  handleFetchData(data: CouponListResponse | string): void {
    if (typeof data == "string") {
      this.$_haii.analytics.errorEvent(data);
      this.$_errorMessage(data);
      return;
    }
    this.$_haii.analytics.readEvent(
      this.$_haiiName() + "_handleFetchData",
      null,
      {
        filter: JSON.stringify({
          companyName: this.filter.institutionName
            ? this.filter.institutionName
            : undefined,
          code: this.filter.couponCode ? this.filter.couponCode : undefined,
        }),
        sorting: JSON.stringify({
          keys: ["_cts"],
          dirs: this.dataOptions?.sortDesc[0] ? ["DESC"] : ["ASC"],
        }),
        pagination: JSON.stringify({
          limit: this.dataOptions?.itemsPerPage,
          page: this.dataOptions?.page,
        }),
      }
    );

    this.institutionList = data;
  }

  async onSearch(): Promise<void> {
    this.dataOptions.page = 1;
    await this.routeChange();
  }

  // 브라우저에서 url 복사 시에도 검색 결과 화면이 유지하기위해 설정
  async routeChange(ev?: Event | string): Promise<void> {
    //검색 옵션 base61 형태로 변환
    const query = this.$_haii.util.queryEncode(this.filter, this.dataOptions);

    // 기존 query 와 다르다면 $route.push 실행
    if (this.$route.query[this.$_haiiName()] !== query) {
      await this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          [this.$_haiiName()]: query,
        },
      });
    }
  }

  async onSearchReset(): Promise<void> {
    this.filter = {};
    // 필터값이 없으면 undefined
    if (this.$route.query[this.$_haiiName()]) {
      await this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          [this.$_haiiName()]: undefined,
        },
      });
    } else {
      await this.fetchData();
    }
  }

  async onRouteChanged(): Promise<void> {
    const query = this.queryDecoded;
    if (query) {
      this.filter = query.filter || this.DEFAULT_FILTER;
      this.dataOptions = query.dataOptions || this.DEFAULT_DATA_OPTIONS;
    }
    await this.fetchData();
  }

  moveInstitution({ UUID }: CouponResponse): void {
    this.$router.push(`/institutions/${UUID}`);
  }

  // 테이블 item row 에 클래스를 부여하는 메서드
  for_hover(): string {
    return "for_hover";
  }

  async onPaginationUpdate(value: Partial<DataOptions>): Promise<void> {
    Object.assign(this.dataOptions, value);
    await this.routeChange();
  }

  async getCompanyList(): Promise<void> {
    const data = await this.$_apiHelper().v1AdminListCompaniesName();
    if (typeof data === "string") {
      return this.$_errorMessage(data);
    }

    data.companies?.forEach((value) => {
      this.companyList.push(value.companyName);
    });
  }
}
